/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/

.intro {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      45deg,
      rgba(212, 134, 57, 0.8),
      rgba(219, 171, 99, 0.8)
    ),
    url("../../../img/intro_photo.jpg") center top no-repeat;
  background-size: cover;
  position: relative;
}

.intro .intro-text {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: calc(50% - 30px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.intro .intro-subHeader {
  color: #eae6e6;
}

.intro h2 {
  margin: 0 0 30px 0;
  padding: 0 15px;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  color: #fff;
}

.intro .btn-get-started {
  border-radius: 50px;
  margin: 10px;
  background-color: transparent;
}

.intro .btn-get-started:hover {
  background-color: #fff;
  color: #d48639;
}

.intro .product-screens {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 50%;
}

.intro .product-screens img {
  box-shadow: 0px -2px 19px 4px rgba(0, 0, 0, 0.29);
}

.intro .product-screens .product-screen-1 {
  position: absolute;
  z-index: 30;
  left: calc(50% + 54px);
  bottom: 0;
  top: 30px;
}

.intro .product-screens .product-screen-2 {
  position: absolute;
  z-index: 20;
  left: calc(50% - 154px);
  bottom: 0;
  top: 90px;
}

.intro .product-screens .product-screen-3 {
  position: absolute;
  z-index: 10;
  left: calc(50% - 374px);
  bottom: 0;
  top: 150px;
}

@media (min-width: 1025px) {
  .intro {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  .intro h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .intro h3 {
    padding: 0 10px;
  }
}
