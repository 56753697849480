.call-to-action {
  overflow: hidden;
  background: linear-gradient(rgba(212, 134, 57, 0.2), rgba(219, 171, 99, 0.2)),
    url(../../../img/coming_soon_photo.jpg) fixed center center;
  background-size: cover;
  padding: 80px 0;
}

.section-header .section-title {
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  color: #fff;
}

.section-header .section-description {
  text-align: center;
  color: #dad8d8;
  font-weight: 400;
}

.parentContainer {
  padding: 30px 0;
}
.childContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;
  height: 100px;
}

.imgContainer {
  height: 60%;
  margin-right: 20px;
}

.nameContainer {
  width: 80%;
}

.imgSize {
  height: 100%;
}

.marginNone {
  margin: 0;
  font-weight: 700;
}
