* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x:hidden;
}

body {
  background: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

a {
  color: #d48639;
}
a:hover,
a:active,
a:focus {
  color: #1dc9ce;
  outline: none;
  text-decoration: none;
}
p {
  padding: 0;
  margin: 0 0 30px 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Sections Header
--------------------------------*/

.section-header .section-title {
  font-size: 32px;
  color: #111;
  text-align: center;
  font-weight: 400;
}

.section-header .section-description {
  text-align: center;
  color: #777;
  font-style: italic;
}

.section-header .section-divider {
  display: block;
  width: 60px;
  height: 3px;
  background: #d48639;
  background: linear-gradient(0deg, #d48639 0%, #dbab63 100%);
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Section with background
--------------------------------*/

.section-bg {
  background: #e9e9e9;
}

.arrow {
  left: 124px;
}

/* Mobile Nav body classes */

body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

.cc-btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  border: 2px solid #fff;
  color: #fff;
  background-color: #d48639;
}
.cc-btn:hover {
  transition: 0.5s;
  color: #d48639;
  background: #fff;
}

.cc-btn:focus {
  outline: 0;
}

.display-5 {
  font-size: 18px;
  line-height: 30px;
}

.dropdown-menu {
  min-width: 50px;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}
