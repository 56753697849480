body {
  background: #fff;
}

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.notFound h1 {
  font-size: 70px;
}

.notFound h2 {
  text-align: center;
}

.notFound a {
  background: linear-gradient(45deg, #dbab63, #d48639);
  border: 0;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
}

.notFound a:hover {
  background: #d48639;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 769px) {
  .notFound h1 {
    font-size: 50px;
  }
  .notFound h2 {
    font-size: 30px;
    width: 80%;
  }
}
