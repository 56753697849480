.contact {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 0;
  overflow: hidden;
}

.contact .contact-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  color: #d48639;
}

.contact .contact-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  color: #888;
}

.contact .social-links {
  padding-bottom: 20px;
}

.contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #d48639;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #d48639;
}

.contact .social-links a:hover {
  background: #d48639;
  color: #fff;
}

.contact .logoMedia img {
  margin-bottom: 50px;
  min-width: 150px !important;
}

.contact .info {
  color: #333333;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.contact .info > div {
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;
  width: 100%;
  margin: 10px auto;
}

.contact .socialLinks {
  gap: 40px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.contact .info i,
.contact .socialLinks i {
  color: #d48639;
  float: left;
  line-height: 1;
}
.contact .info i {
  font-size: 32px;
}

.contact .socialLinks i {
  font-size: 36px;
}
.contact .socialLinks i:hover {
  cursor: pointer;
  color: #d37e29;
}

.contact .info p {
  padding: 0 0 10px 42px;
  text-align: left;
  margin: 0;
  line-height: 28px;
  font-size: 14px;
}

.contact .form #sendmessage {
  color: #d48639;
  border: 1px solid #d48639;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.contact .form #sendmessage.show,
.contact .form #errormessage.show,
.contact .form .show {
  display: block;
}

.contact .form {
  width: 100%;
}

.contact .form input,
.contact .form textarea {
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  border-radius: 1;
  border: 1px solid #d48639;
  box-shadow: none;
  font-size: 14px;
}

.contact .form textarea {
  height: 177px;
}

.contact .form .validation {
  color: #dc3232;
  font-weight: 400;
  font-size: 13px;
}

.validation-border {
  border-color: #dc3232;
}

.visibility-visible {
  visibility: visible !important;
}

.contact .form button[type="submit"] {
  background: linear-gradient(45deg, #dbab63, #d48639);
  border: 0;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
}

.contact .form button[type="submit"]:hover {
  background: #d48639;
  color: #fff;
  cursor: pointer;
}

.hideMessage {
  visibility: hidden;
  padding: 10px 0;
}

.showMessage {
  visibility: visible;
}

.error_message {
  color: red;
}

.success_message {
  color: green;
}

@media (max-width: 769px) {
  .contact {
    padding: 40px 0;
  }
  .contactMobile > div {
    width: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .info > div {
    padding: 5px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .info > div i {
    margin-bottom: 5px;
  }
  .info > div p {
    text-align: center !important;
    padding: 0 !important;
    font-size: 16px !important;
  }

  .form {
    width: 90% !important;
    margin: 0 auto;
  }

  .contact .logoMedia {
    margin-bottom: 20px;
    width: 70%;
  }

  .contact .logoMedia img {
    margin: 20px 0 !important;
  }

  .contact .socialLinks i,
  .contact .info i {
    font-size: 40px;
  }
}
