/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
}

#header #logo {
  position: relative;
  top: -17px;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
  height: 100px;
  transition: all 0.5s;
}

#header.header-fixed {
  background: #d48639;
  padding: 20px 0;
  height: 72px;
  transition: all 0.5s;
}

#header.header-fixed #logo img {
  transition: all 0.5s;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/

/* Nav Menu Essentials */

/* Nav Menu Container */

#nav-menu-container {
  float: right;
  margin: 0;
}

/* 
Animation class
----------------------
*/

.scaleUp {
  transition: 0.75s;
}

.scaleDown {
  transition: 0.75s;
  transform: scale(0.6) translate(-45px, -30px);
}

.scrolledUp {
  height: 92px;
  transition: 0.5s;
  padding: 30px 0;
}

.scrolledDown {
  background-color: #d48639;
  transition: 0.5s;
  height: 72px;
  padding: 20px 0;
}

.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Styling */

.nav-menu a {
  padding: 0 8px 10px 8px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  outline: none;
}

.nav-menu > li {
  margin-left: 15px;
}

.nav-menu ul {
  margin: 4px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}

.nav-menu ul li:hover > a {
  color: #d48639;
}

.nav-menu ul ul {
  margin: 0;
}

@media (max-width: 992px) {
  #nav-menu-container {
    display: none;
  }
}
@media (max-width: 1200px) {
  .nav-menu a {
    padding-left: 1px;
    padding-right: 1px;
  }
}

.nav-menu .dropdown-item {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
