.p-and-p {
  padding: 60px 0;
  background: #fff;
}

.p-and-p img {
  max-width: 100%;
  opacity: 0.5;
  transition: 0.3s;
  padding: 15px 0;
}

.p-and-p img:hover {
  opacity: 1;
}

/* Sections Header
--------------------------------*/

.section-header .section-title {
  font-size: 32px;
  color: #111;
  text-align: center;
  font-weight: 400;
}

.section-header .section-description {
  text-align: center;
  color: #777;
  font-style: italic;
}

.section-header .section-divider {
  display: block;
  width: 60px;
  height: 3px;
  background: #d48639;
  background: linear-gradient(0deg, #d48639 0%, #dbab63 100%);
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Section with background
--------------------------------*/

.section-bg {
  background: #e9e9e9;
}

@media (max-width: 768px) {
  .p-and-p {
    padding: 40px 0;
  }

  .p-and-p img {
    max-width: 70%;
    margin-top: 30px;
  }
}
