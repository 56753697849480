.back-to-top {
  position: fixed;
  background: linear-gradient(45deg, #DBAB63, #D48639);
  color: #fff;
  padding: 2px 20px 8px 20px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
  right: 15px;
  bottom: 0;
}

.back-to-top:focus {
  background: linear-gradient(45deg, #DBAB63, #D48639);
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #D48639;
  color: #fff;
}