.team {
  padding: 60px 0;
  overflow: hidden;
}

.team .member {
  text-align: center;
  margin-bottom: 20px;
}

.team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  height: 330px;
}

.team .member .pic img {
  object-fit: contain;
  max-width: 100%;
  height: 100%;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.team .member span {
  display: block;
  font-size: 14px;
}

.team .member .memberDescription {
  margin-top: 15px;
  font-size: 14px;
}

.team .member .social {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.team .member .social a {
  color: #b3b3b3;
}

.team .member .social a:hover {
  color: #d48639;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Section with background
--------------------------------*/

.section-bg {
  background: #e9e9e9;
}

/* Sections Header
--------------------------------*/

.section-header .section-title {
  font-size: 32px;
  color: #111;
  text-align: center;
  font-weight: 400;
}

.section-header .section-description {
  text-align: center;
  color: #777;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 30px;
}

.section-header .section-divider {
  display: block;
  width: 60px;
  height: 3px;
  background: #d48639;
  background: linear-gradient(0deg, #d48639 0%, #dbab63 100%);
  margin: 0 auto;
  margin-bottom: 20px;
}

@media (max-width: 769px) {
  .team {
    padding: 40px 0;
  }
  .section-header .section-description {
    margin-bottom: 0;
  }
  .team .member span,
  .team .member .memberDescription {
    font-size: 16px;
  }
}
