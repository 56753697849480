/* Mobile Nav Styling */

#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 999;
  background: rgba(52, 59, 64, 0.9);
  width: 260px;
  overflow-y: auto;
  transition: none;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #d48639;
}

#mobile-nav ul .menu-item-active {
  color: #d48639;
}

#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}
#lang {
  position: fixed;
  display: none;
  cursor: pointer;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  right: 30px;
  top: 0;
  z-index: 2000;
}

#mobile-nav-toggle i,
#lang {
  color: #fff;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 998;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(52, 59, 64, 0.9);
  display: none;
}

@media (max-width: 992px) {
  #mobile-nav-toggle,
  #lang {
    display: inline;
    
  }
  #lang {
    margin: 20px 40px 0 0;
  }
}
