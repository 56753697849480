.portfolio {
  padding: 60px 0;
  background: #e9e9e9;
}

.carousel_text {
  margin: auto 0;
}

.carousel_text h3 {
  color: #282828;
  text-align: left;
}
.carousel {
  display: flex;
  min-height: 440px;
}

.carousel_item {
  background-color: #e9e9e9;
}

.carousel .carousel_caption {
  position: unset;
  margin: auto 0;
  height: 550px;
}

.carousel_text span:hover {
  cursor: pointer;
  color: #d48639;
}

.carousel_text p,
li {
  color: #666666;
  text-align: left;
  line-height: 30px;
  font-size: 17px;
}

.carousel_text span {
  color: #282828;
}

.portfolio li {
  margin: 20px 0;
}

.carousel ul {
  list-style-type: "- ";
  padding-left: 10px;
}

.portfolio a:nth-of-type(1) {
  left: -40px;
}

.portfolio a:nth-of-type(2) {
  right: -40px;
}

.portfolio .playStore img {
  width: 150px;
  border-radius: 10px;
}

.carousel a {
  opacity: 1;
}

.carousel a span:nth-child(2) {
  font-size: 0px;
}

/* left side arrow span svg */
.carousel a:nth-child(2) span:nth-child(1) {
  background-image: none;
}

/* left side arrow */
.carousel a:nth-child(2) span:nth-child(1)::before {
  font-family: "Font Awesome 5 Free";
  content: "\f053";
  font-weight: 600;
  color: #00000045;
  font-size: 30px;
}

/* right side arrow span svg*/
.carousel a:nth-child(3) span:nth-child(1) {
  background-image: none;
}

/* right side arrow */
.carousel a:nth-child(3) span:nth-child(1)::before {
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  font-weight: 600;
  color: #00000045;
  font-size: 30px;
}

.carousel_caption .img_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.carousel_caption img {
  margin: auto 0;
  width: 100%;
}

@media (max-width: 992px) {
  .carousel button {
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #666666;
    margin: 5px;
  }

  .carousel button:focus {
    outline: none;
  }

  .carousel button:active {
    background-color: #d48639 !important;
  }

  .carousel .carousel_caption {
    height: auto;
  }

  .portfolio .playStore {
    margin: 30px 0 10px 0;
  }
}

@media (max-width: 768px) {
  .portfolio {
    padding: 40px 0;
  }
  .carousel .carousel_caption {
    padding: 0;
  }

  .carousel_text h3 {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .carousel_text p {
    text-align: left;
  }

  .carousel .carousel_caption {
    height: 1000px;
    padding-bottom: 30px;
  }

  .carousel .carousel_text {
    margin: 0;
  }

  .portfolio a:nth-of-type(1) {
    left: 0px;
  }

  .portfolio a:nth-of-type(2) {
    right: 0px;
  }
}
