/* About Us Section
--------------------------------*/

.about {
  padding: 60px 0;
  overflow: hidden;
}

.contentinnercontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.contentinnercontainer h4 {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  color: #666666;
}

.contentinnercontainer span {
  color: #2b2b2b;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}

.about .about-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
}

.about .about-img img {
  max-width: 100%;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.contentinnercontainer h4 {
  line-height: 30px;
}

.section-bg {
  background: #e9e9e9;
}

@media (max-width: 1350px) {
  .about h4 {
    font-size: 20px;
  }
  .about .contentinnercontainer span {
    font-size: 22px;
  }
  .contentinnercontainer {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .about h4 {
    font-size: 18px;
  }
  .about .contentinnercontainer span {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .content {
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .about {
    overflow: hidden;
  }
  .about .about-img {
    padding: 0;
  }
}
