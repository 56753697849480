/* Product Featuress Section
--------------------------------*/

.features {
  background: #fff;
  padding: 0 0 0 60px;
  overflow: hidden;
}

.features .features-img {
  text-align: center;
  padding-top: 20px;
}

.features img {
  max-width: 100%;
}
/* Product Advanced Featuress Section
  --------------------------------*/
.advanced-features {
  overflow: hidden;
}

.advanced-features .features-row {
  padding: 30px 0 30px 0;
}

.advanced-features h1 {
  color: #2b2b2b;
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
}
.advanced-features p {
  margin-bottom: 40px;
}

.advanced-features ul {
  margin: 0;
  padding-left: 20px;
}

.advanced-features li {
  margin-bottom: 25px;
}
.advanced-features li,
.advanced-features p {
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}

.advanced-features .advanced-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  height: 600px !important;
  overflow: hidden;
  max-width: 100%;
  padding: 0;
  margin: 0;
}
.advanced-features .advanced-img img {
  max-width: 100%;
  height: 600px !important;
}

@media (max-width: 1200px) {
  .advanced-features .advanced-img {
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .advanced-features p {
    margin: 40px 0;
  }
}

@media (max-width: 768px) {
  .features {
    background: #fff;
    padding: 20px 0 0 0;
    overflow: hidden;
  }
  .advanced-features h1 {
    padding-top: 20px;
  }
  .advanced-features .advanced-img,
  .advanced-features .advanced-img img {
    max-height: 550px;
  }
}
