.badgeWrapper:first-of-type {
  max-width: 220px !important;
}

.badgeWrapper {
  max-width: 120px;
  margin: 0 20px;
}
@media (max-width: 1200px) {
  .badgeWrapper:first-of-type {
    max-width: 200px !important;
  }
}

@media (max-width: 992px) {
  .badgeWrapper:first-of-type {
    max-width: 190px !important;
  }
}

@media (max-width: 768px) {
  .badgeWrapper:first-of-type {
    max-width: 340px !important;
  }
  .badgeWrapper:first-of-type img {
    max-width: 250px !important;
  }
  .badgeWrapper {
    max-width: 180px !important;
    margin: 0;
  }
}
