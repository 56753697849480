#footer {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  color: #333;
  font-size: 14px;
}

#footer .credits {
  font-size: 13px;
  color: #888;
}



.footer-links a {
  color: #666;
  padding-left: 15px;
}

.footer-links a:first-child {
  padding-left: 0;
}

.footer-links a:hover {
  color: #D48639;
}

@media (max-width: 769px) {
  .footer-links a {
    padding-left: 0;
  }
  .footer-links {
  column-gap: 15px;
  row-gap: 3px;
}

}


