.careers {
  overflow: hidden;
  padding: 60px 0;
}

.careers_text {
  font-size: 16px;
  line-height: 30px;
}

.careers i {
  color: #fff;
  font-size: 64px;
  transition: 0.5s;
  float: left;
  padding: 0 15px 0px 0;
  line-height: 1;
}

.careers i:before {
  background: #d48639;
  background: linear-gradient(45deg, #d48639 0%, #dbab63 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.careers .advanced-feature-img-left {
  max-width: 100%;
  float: left;
  padding: 0px 70px 30px 0;
}

@media (min-width: 769px) {
  #careers img {
    padding-top: 120px;
    margin-top: -200px;
  }
}

@media (max-width: 768px) {
  .careers {
    padding: 40px 0;
  }

  .careers .features-row img {
    max-width: 100%;
    float: none;
    margin-left: 0;
    padding: 0px 0px 30px 0px;
  }
}
